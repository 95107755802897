import React from 'react';

import { Container } from './styled';
import ImageSlider from '../ImageSlider'

// Commit
function CardInfo({ type, image, title, text, description, buttonLabel, ulList, buttonHref, id, icons }) {
    return (
        <Container className="space-section" image={image} type={type}>
            {!image?.map ? <img className="banner banner-desktop" src={image} alt="" /> : <ImageSlider images={image} id={id} className="banner banner-desktop" />}

            <div className="div-info-men" id={id}>
                <div className="tam-txt-info">
                    <h2 className="title-info">{title}</h2>
                    
                    {!image?.map ? <img className="banner banner-mobile" src={image} alt="" /> : <ImageSlider images={image} id={id} className="banner banner-mobile" />}
                    
                    <p className="description">{description}</p>
                </div>


                <br />
                <p className='description'>
                    {text}
                </p>

                {type === 1 &&
                    <ul className="ul-list">
                        {ulList.map((li) => <li>{li}</li>)}
                    </ul>}

                {(buttonLabel && buttonHref) &&
                    <div className="box-button-link">
                        <a href={buttonHref} target="blank">
                            <button className="button-link" type="button">{buttonLabel}</button>
                        </a>

                    </div>

                }
            </div>

            <div>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
                    <a href="https://wa.me/5516993900954?text=Olá, quero informações sobre o Método GBT"
                        style={{
                            position: "fixed",
                            width: "60px",
                            height: "60px",
                            bottom: "40px",
                            right: "40px",
                            backgroundColor: "#25d366",
                            color: "#FFF",
                            borderRadius: "50px",
                            textAlign: "center",
                            fontSize:"30px",
                            boxShadow: "1px 1px 2px #888",
                            zIndex: "1000"}} target="_blank" without rel="noreferrer">

                        <i style={{marginTop: "16px"}} class="fa fa-whatsapp"></i>
                    </a>
            </div>
        </Container>
    );
}

export default CardInfo;