import styled from "styled-components";

export const Slider = styled.div`
    position:relative;
    width:100%;

    /* max-width:600px; */

    display: flex;
    align-items: center;


    img{
        width:100%;
    }

    .arrow{
        cursor: pointer;
        background-color:transparent;
        border:none;
        height:50px;
        width:50px;
        top: 50%;
        transform: translate(0%, -50%);
        position:absolute;
    }

    @media(max-width:800px){
        width:100%;

        button.arrow-right {
            right: 4%;
        }
        button.arrow-left {
            left: 4%;
        }
    }

    .arrow-right{
        right: 5rem;
    }

    .arrow-left{
        left: 5rem;
    }

    .arrow img{
        width:50px;
        height:50px
    }

    .arrow-right img{
        transform: rotate(180deg);
    }
`;