import React, { useState } from 'react';
import { Container } from './styled';
import TinySlider from 'tiny-slider-react';
import arrow from '../../assets/images/arrow.svg';

// Commit
const CardInfoShowMore = ({ type, image, title, text1, text2, description, buttonLabel, ulList, buttonHref, id, icons }) => {
    const settings = {
        lazyload: true,
        nav: false,
        mouseDrag: true,
        prevButton: '.slider .arrow-left',
        nextButton: '.slider .arrow-right',
        gutter: 1
    };

    const [show, setShow] = useState(false);

    return (
        <Container type={type} className="space-section">
            <div className="slider banner-desktop" id=''>
                <TinySlider settings={settings}>
                    {image.map((item, idx) => {
                        return(
                            <div key={idx} style={{ position: "relative" }}>
                                <img
                                    src={item}
                                    alt=""
                                />
                            </div>
                        )
                    })}
                </TinySlider>
                <button className="arrow arrow-left"><img src={arrow} alt="left" /></button>
                <button className="arrow arrow-right"><img src={arrow} alt="right" /></button>
            </div>
            
            <div className="div-info-men" id={id}>
                <div className="tam-txt-info">
                    <h2 className="title-info">{title}</h2>

                    <div className="slider banner-mobile" id=''>
                    <TinySlider settings={settings}>
                        {image.map((item, idx) => {
                            return(
                                <div key={idx} style={{ position: "relative" }}>
                                    <img
                                        src={item}
                                        alt=""
                                    />
                                </div>
                            )
                        })}
                    </TinySlider>
                    <button className="arrow arrow-left"><img src={arrow} alt="left" /></button>
                    <button className="arrow arrow-right"><img src={arrow} alt="right" /></button>
                </div>
                </div>
                <p className="description">{description}</p>
                
                <br />

                {(!show) ? (
                    <p className='description'>
                        {text1}
                    </p>
                ) :  (
                    <p className='description'>
                        {text1}
                        {text2}
                    </p>
                )}
                
                <button className="btn space" onClick={() => setShow(!show)}>
                    {(show ? ("Leia menos") : ("Leia mais"))}
                </button>

                {type === 1 &&
                    <ul className="ul-list">
                        {ulList.map((li) => <li>{li}</li>)}
                    </ul>}

                {(buttonLabel && buttonHref) &&
                    <div className="box-button-link">
                        <a href={buttonHref} target="blank">
                            <button className="button-link" type="button">{buttonLabel}</button>
                        </a>
                    </div>

                }
            </div>

            <div>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
                    <a href="https://wa.me/5516993900954?text=Olá, quero informações sobre o Método GBT"        
                        style={{
                            position: "fixed",
                            width: "60px",
                            height: "60px",
                            bottom: "40px",
                            right: "40px",
                            backgroundColor: "#25d366",
                            color: "#FFF",
                            borderRadius: "50px",
                            textAlign: "center",
                            fontSize:"30px",
                            boxShadow: "1px 1px 2px #888",
                            // zIndex: "1000"}} target="_blank" without rel="noreferrer">
                            zIndex: "1000"}} target="_blank" rel="noreferrer">

                        <i style={{marginTop: "16px"}} className="fa fa-whatsapp"></i>
                    </a>
            </div>
        </Container>
    );
}

export default CardInfoShowMore;