import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    margin: 30px 0;
    margin-bottom: 50px;
    max-width: 600px;
    gap: 15px;
    display: flex;

    .banner {
        position: none;
        display: none;
    }

    .banner-mobile {
        display: none;
    }

    .banner-desktop {
        display: block;
    }

    .slider{
        position:relative;
        width: 55%;
        /* max-width:600px; */

        display: flex;
        align-items: center;
        order: 1;

    }
    .slider img{
        max-height:600px;
        width: 100%;
    }


    .slider .arrow{
        background-color:transparent;
        border:none;
        height:50px;
        width:50px;
        position:absolute;
    }

    @media(max-width:800px){
        margin: 0px 0;

        .slider{
            width:100%;
        }

        .slider .arrow{
            top:40%;
        }
    }

    .slider .arrow-right{
        right:0;
    }

    .slider .arrow-left{
        left:0;
    }

    .slider .arrow img{
        width:50px;
        height:50px
    }

    .slider .arrow-right img{
        transform: rotate(180deg);
    }

    .ul-list {
        width: 100%;
        padding: 10px 17px;
        li {
            font-size: var(--unnamed-font-size-14);
            line-height: var(--unnamed-line-spacing-24);
            color: var(--unnamed-color-4a3f32);
        }
    }

    .title-info {
        margin: 30px 0;
        font-style: var(--unnamed-font-style-normal);
        font-weight: var(--unnamed-font-weight-600);
        font-size: var(--unnamed-font-size-18);
        line-height: var(--unnamed-line-spacing-18);
        letter-spacing: var(--unnamed-character-spacing-1);
        color: var(--unnamed-color-d5b68f);
        text-transform: uppercase;
    }

    .description {
        font-weight: var(--unnamed-font-style-normal);
        font-size: var(--unnamed-font-size-14);
        line-height: var(--unnamed-line-spacing-24);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-4a3f32);
        margin: 0;
    }

    .box-button-link {
        display: flex;
        justify-content: flex-end;
        padding-top: 40px;

        .button-link {
            padding: 15px 30px;
            background-color: #ffffff;
            font: var(--unnamed-font-style-normal) normal bold var(--unnamed-font-size-14)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-raleway);
            color: var(--unnamed-color-4a3f32);
            text-align: left;
            font: normal normal bold 14px/24px Raleway;
            letter-spacing: 1.96px;
            color: #4A3F32;
            text-transform: uppercase;
            border: 1px solid #4A3F32;
            cursor: pointer;

            :hover {
                background-color: #efefef7d;
            }
        }
    }

    .btn {
        padding: 10px 20px;
        background-color: var(--unnamed-color-d5b68f);
        color: #fff;
        border: 1px solid var(--unnamed-color-d5b68f);
        border-radius: 5px;
        cursor: pointer;
    }

    .btn:hover {
        opacity: 0.9 ;
    }

    .space {
        margin-top: 10px;
    }

    @media only screen and (max-width: 800px) {
        .banner-desktop {
            display: none;
        }
    }

    @media (min-width: 800px) {
        ${(p) => p.type === 1 ? `
            max-width: 1400px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row-reverse;

            .div-info-men {
                width: 52%;
                padding-right: 30px
            }

            .banner {
                position: none;
                display: none;
            }

            .banner-desktop {
                display: inline-block;
            }

            .banner-mobile {
                display: none;
            }
        }
        ` : p.type === 2 ? `@media (min-width: 800px) {
            height: 80vw;
            max-width: 1400px;
            max-height: 105vh;
            background-image: url('https://adrianmaycon.github.io/static/media/image4.876726fd.png')};
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding: 40px 67px;
            margin: 60px 0;

            .div-info-men {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .tam-txt-info {
                width: 100%;
                max-width: 200px;
            }

            .banner {
                position: none;
                display: none;
            }

            .banner-desktop {
                display: inline-block;
            }

            .banner-mobile {
                display: none;
            }
        ` : null}
    }
`;