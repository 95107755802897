import React from 'react';
import { FiLinkedin, FiInstagram } from "react-icons/fi";
import { Container } from './styled';

function Card({ name, image, profession, description, linkedin, instagram, crm, rqe }) {

    return (
        <Container>
            <img className="image-profi" src={image} alt={name} />

            <div className="container-infos">
                <div>
                    <h2 className="title-name">{name}</h2>
                    <h3 className="subtitle-name">{profession}</h3>
                    <h4 className="subtitle-name">{crm} &nbsp; {rqe}</h4>
                </div>

                <div className="container-icons">
                    {linkedin && <a href={linkedin} target="blank">
                        <FiLinkedin className="linkedin-icon" />
                    </a>}

                    {instagram && <a href={instagram} target="blank">
                        <FiInstagram className="insta-icon" />
                    </a>}
                </div>
                {description.map((d) =>
                    <>
                        <p className="description">{d}</p>
                        <br />
                    </>
                )}
            </div>

        </Container>
    );
}

export default Card;