import React from 'react';
// import image5 from '../../assets/images/image5.webp';
import { ContainerFooter } from './styled';

// Template image.
// import placeholder580x720 from "../../assets/images/Placeholders/Placeholder_580x720.jpeg"

function Footer() {
  return (
    <ContainerFooter className="space-section">
      <div className="container-infos-contato">
        {/* <img className="image-footer image-footer-desktop" src={placeholder580x720} alt="" /> */}

        <div className="container-posi-footer" id="contato">
          <div>
            <h2 className="title">CONTATOS</h2>

            {/* <img className="image-footer image-footer-mobile" src={placeholder580x720} alt="" /> */}

            <p className="description">
              <br />
              <br />
            </p>
          </div>
          <div>
              <p className="description">
                <b>
                  Endereço
                </b>
                <br />
                <br />
                Av. Dr. Carlos Botelho, 1380<br />
                Centro<br />
                São Carlos - SP<br />
              </p>
          </div>
          <div>
              <p className="description">
                <b>
                  Contato
                </b>
                <br />
                <br />
                
                13561-003, Brasil<br/>
              </p>
          </div>
        </div>
        <div>
          <p className="description">
            Envie um email com suas necessidades e dúvidas que retornaremos o mais breve possível: <b>contato@redeodontologia.com.br</b>
          </p>
        </div>
      </div>
    </ContainerFooter>
  );
}

export default Footer;
