import React, { useState } from 'react';

// Imagem Sobre
//import sobreImage from '../../assets/images/Sobre-aille-clinic.webp';

// Imagem do slide de Nossas Unidades.
// import nossasUnidadesImg1 from "../../assets/images/banner2.jpg";
// import nossasUnidadesImg2 from "../../assets/images/nossasunidades2.webp";
// import nossasUnidadesImg3 from "../../assets/images/nossas_unidades_3.webp";
// import nossasUnidadesImg4 from "../../assets/images/nossas_unidades_4.webp";
// import nossasUnidadesImg5 from "../../assets/images/nossas_unidades_5.webp";
// import nossasUnidadesImg6 from "../../assets/images/nossas_unidades_6.webp";
// import nossasUnidadesImg7 from "../../assets/images/nossas_unidades_7.webp";
// import nossasUnidadesImg8 from "../../assets/images/nossas_unidades_8.webp";
// import nossasUnidadesImg9 from "../../assets/images/nossas_unidades_9.webp";
// import nossasUnidadesImg10 from "../../assets/images/nossas_unidades_10.webp";
// import nossasUnidadesImg11 from "../../assets/images/nossas_unidades_11.webp";
// import nossasUnidadesImg12 from "../../assets/images/nossas_unidades_12.webp";

// ___________________________________

import logo from '../../assets/images/Rede Odontologia Final.svg';
//import drMurillo from '../../assets/images/dr-murillo.webp';
// import draAlessandra from '../../assets/images/dra-alessandra.webp';
// import image3 from '../../assets/images/image3.PNG';
// import image4 from '../../assets/images/image4.webp';
// import bannerHome from '../../assets/images/banner2.jpg';
import CardProf from '../../components/CardProf';
// import CardText from '../../components/CardText';
import CardInfo from '../../components/CardInfo';
// import CardStore from '../../components/CardStore';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';
import Rights from '../../components/Rights';
import CardInfoShowMore from '../../components/CardInfoShowMore';
import { IoReorderTwoOutline, IoCloseOutline } from "react-icons/io5";
import { Container, Banner } from './styled';

/**
 * Placeholders.
 * ___________________________________
 */

// import placeholder720x420 from "../../assets/images/Placeholders/Placeholder_720x420.jpeg"
// import placeholder580x720 from "../../assets/images/Placeholders/Placeholder_580x720.jpeg"

//Imagens
// import quemSomos from "../../assets/images/newImages/quem_somos.jpg"
import home from "../../assets/images/newImages/home.webp"
import comoFunciona from "../../assets/images/COMO_FUNCIONA.jpg"
import oqueFazemos from "../../assets/images/newImages/oq_fazemos.jpg"
import doutorRomao from "../../assets/images/newImages/romao.jpg"
import prevencao from "../../assets/images/newImages/prevencao.jpg"

// ___________________________________

import imgAfterGbt from "../../assets/images/newImages/Group 3ANTES-DEPOIIS.png";
import imgAfterGbtDisclosing from "../../assets/images/newImages/Group 2ANTES-DEPOIIS.png";
import imgBeforeGbt from "../../assets/images/newImages/Group 1ANTES-DEPOIIS.png";

// const dataText = [
//     {
//         image: quemSomos,
//         title: ["QUEM SOMOS"],
//         subtitle: "",
//         menssage: [
//             "A Rede Odontologia é um centro especializado na prevenção em saúde odontológica com sede em São Carlos, região central do estado de São Paulo.",
//             "Fundada em 2022 e dirigida pelo Dr. Romão A. de S. Mansano, a Rede Odontologia é certificada pelo Protocolo GBT, método inovador de limpeza dentária desenvolvido pela empresa EMS (Suíça).",
//             "O GBT objetiva a preservação dos tecidos e dos dentes (também de próteses, implantes e aparelhos ortodônticos) através de procedimentos profiláticos indolores e minimamente invasivos.A qualidade é responsabilidade nossa e se traduz em detalhes desde o ambiente até a equipe médica amplamente especializada e os tratamentos mais avançados.",
//             "A profilaxia é pressuposto fundamental para a prevenção de problemas ortodônticos, para a manutenção da saúde e da qualidade de vida de crianças e adultos. E a missão da Rede Odontologia é promover o acesso a orientações sobre cuidados e tratamentos rápidos, indolores e com bom custo benefício,  para que os nossos pacientes mantenham a sua saúde bucal em dia!",
//         ],
//         type: 1,
//     },
//     {
//         image: [
//             placeholder580x720,
//             placeholder580x720,
//             placeholder580x720,
//             placeholder580x720,
//             placeholder580x720,
//             placeholder580x720,
//             placeholder580x720,
//             placeholder580x720,
//             placeholder580x720,
//             placeholder580x720,
//             placeholder580x720,
//             placeholder580x720,
//         ],
//         title: ["Nossas Unidades"],
//         subtitle: "",
//         menssage: [],
//         type: 2,
//     },
// ]

const dataProfi = [
    {
        id: 1,
        image: doutorRomao,
        name: "Dr. Romão A. de S. Mansano",
        profession: "Mestre e Doutor em Reabilitação Oral",
        description: ["Dr. Romão graduou-se em odontologia pela Universidade do Sagrado Coração - Bauru, em 2002", "É especialista em Implantologia pela ABO, possui títulos de Mestrado e Doutorado em Reabilitação Oral pela FOB-USP Bauru e mais de 10 anos de experiência com próteses, periodontia e implantes.","O Dr. Romão é um dos dentistas pioneiros na implantação do método GBT de profilaxia dentária no Brasil."],
        // linkedin: "http://buscatextual.cnpq.br/buscatextual/visualizacv.do?metodo=apresentar&id=K4233200U5",
        // instagram: "https://www.instagram.com/drmurillofraga/",
        // crm: 'CRM-105.339',
        // rqe: 'RQE: 29.085',
    }/* ,
    {
        id: 2,
        image: placeholder580x720,
        name: "DRA. ALESSANDRA FRAGA",
        profession: "Dermatologista",
        description: "Dra Alessandra Fraga é médica formada pela Faculdade de Ciências Médicas da Santa Casa de SP, com residência médica em dermatologia na mesma instituição. Especializada em laser na Harvard Medical School e em oncologia cutânea na Itália. Atualmente atua e é diretora da Aille Clinic e Clínica no Hospital Israelita Albert Einstein. Membro titular da Sociedade Brasileira de Dermatologia e da American Academy of Dermatology.",
        linkedin: "#",
        instagram: "https://www.instagram.com/draalessandrafraga/",
        crm: 'CRM-120.489',
    }, */
]

// Adição do texto
const oQueFazemos = {
    id: 1,
    type: 1,
    image: oqueFazemos,
    buttonHref: "",
    buttonLabel: "",
    idLink: "tratamentos",
    title: "O QUE FAZEMOS",
    text: "Outros tratamentos",
    ulList: [
        "Ortodontia", 
        "Implantologia", 
        "Tratamentos estéticos.", 
        "Implantes", "Próteses", 
        "Periodontia", 
        "Tratamento de estética / preventiva"
    ],
    description: "Tratamento de prevenção através do método GBT de limpeza dentária."
}

const dataInfo = [
    {
        id: 2,
        type: 1,
        image: [
            imgAfterGbt,
            imgAfterGbtDisclosing,
            imgBeforeGbt,
        ],
        buttonHref: "",
        buttonLabel: "",
        idLink: "metodo-gbt",
        title: "O MÉTODO GBT",
        description: "",
        text: "Criado pela EMS (Suíça), líder global no desenvolvimento e fabricação de dispositivos para tratamentos dentários e ortopédicos, entre outros, o GBT é o método mais inovador de limpeza dental profissional, recomendado a crianças e adultos de quaisquer idades, com ou sem comorbidades. A proposta do método GBT é promover a prevenção de doenças bucais e a preservação de próteses, aparelhos e implantes, através de método inovador de profilaxia, que alia equipamentos e produtos com alta tecnologia. O GBT é composto por um programa de até 8 passos, determinados de forma individualizada, de acordo com as necessidades e particularidades de cada paciente. Os tratamentos realizados através do GBT são minimamente invasivos, indolores e rápidos, com eficiência e segurança comprovados por especialistas de todo o mundo. Conheça as principais vantagens do GBT: ",
        ulList: [
            "Tratamento inovador, minimamente Invasivo",
            "Indolor, com elevado nível de conforto",
            "Tratamentos estéticos.",
            "Rápido",
            "Eficaz para a prevenção de problemas como cáries, gengivite e periodontite, entre outras",
            "Seguro para pessoas de quaisquer idades, com ou sem doenças pré-existentes",
            "Preserva tecidos e promove e a saúde dental",
            "Auxilia na manutenção de próteses, implantes e aparelhos ortodônticos."
        ],
    },
    {
        id: 3,
        type: 1,
        image: [comoFunciona],
        buttonHref: "",
        buttonLabel: "",
        idLink: "tratamentos",
        title: "Como funciona",
        description: "",
        text: "A aplicação do método GBT parte do princípio da individualização do tratamento. Isso porque, cada pessoa tem as suas características genéticas, sociais e comportamentais, que impactam de formas diferentes na sua saúde bucal.  Assim, o primeiro passo do Programa é a Avaliação ou anamnese. No próximo passo, um produto com alta tecnologia (Biofilm Disclosure) é distribuído sobre a superfície dos dentes do paciente, revelando as áreas com indicação de placa bacteriana. A mudança de coloração nos locais infectados permite ao profissional orientar de forma mais didática ao paciente sobre como realizar uma limpeza bucal eficiente.",
        text2: "Nesta fase, o profissional faz o planejamento do tratamento. No passo seguinte entra em ação o Air Flow Dental, equipamento completo para limpeza composto de um jato de água que combina ar e um pó a base de eritritol 14μm para a remoção do biofilme (acúmulo de restos de alimento e bactérias nos dentes). Diferente dos aparelhos convencionais, que causam desconforto e até dor, a aplicação do Air Flow é indolor, além de mais rápida. O número de aplicações em um ciclo, assim como a periodicidade indicada para a adesão continuada ao Programa, dependem das condições bucais do paciente. A regularidade no tratamento dentro dos ciclos propostos garante que o paciente não desenvolva problemas relacionados à má higiene bucal, tais como gengivite, periodontite, aftas e até endocardite bacteriana. O método GBT garante ainda a manutenção de implantes e aparelhos ortodônticos. Agende sua avaliação e saiba mais sobre o GBT pode te ajudar na manutenção de sua saúde bucal!",
        ulList: ["Ortodontia", "Implantologia", "Tratamentos estéticos."],
    },
    {
        id: 4,
        type: 1,
        image: prevencao,
        buttonHref: "",
        buttonLabel: "",
        idLink: "tratamentos",
        title: "PREVENÇÃO EM SAÚDE BUCAL COM MÉTODO GBT ",
        description: "",
        text: "",
        ulList: [
            "Cuide da higiene bucal regularmente: Essa é a dica chave para a prevenção de doenças ortodônticas. Escovação minuciosa e uso do fio dental devem acontecer sempre após a ingestão de algum alimento, ou, ao menos, depois das principais refeições.",
            "Mantenha bons hábitos alimentares: A alimentação nutritiva e equilibrada é outra grande aliada do sorriso saudável!",
            "Visite frequentemente o dentista: As visitas ao dentista não devem acontecer somente quando existe dor. Devem ser regulares, para que o profissional realize processos de profilaxia e/ou outros métodos preventivos quando há indicação."
        ],
    }
]

// const dataStore = [
//     {
//         id: 2,
//         type: 2,
//         image: placeholder580x720,
//         title: "Aille Store",
//         description: "Aille clinic medical cosmetics traz a experiência dermatológica e altas concentrações de princípios ativos terapêuticos para cada frasco de seus cosméticos. Com alta performance, essa linha de skincare foi desenvolvida pela dermatologista Alessandra Fraga e pelo cirurgião plástico, Murillo Fraga, que inauguraram no Brasil o conceito de cosmético médico - aquele que contém ingredientes em concentrações efetivamente capazes de tratar e modificar a condição da pele! ",
//         buttonLabel: "Visite a nossa loja",
//         ulList: [""],
//         buttonHref: "https://aillecosmetics.com.br/",
//         idLink: "aille-store",
//         icons: ""
//     },

// ]


function Home() {

    const [open, setOpen] = useState(false)

    function menuFechando() {
        setOpen(false);
        let boxLogo = document.querySelector('.logo-and-text');
        let title = document.querySelector('.subtitleJS');

        if (window.outerWidth < 800) {
            boxLogo.classList.remove('menu-aberto');
            boxLogo.classList.add('menu-fechado');
        } else {
            boxLogo.classList.remove('menu-aberto-desktop');
            title.classList.remove('titulo-hidden');
        }
    }

    function menuAbrindo() {
        setOpen(true);
        let boxLogo = document.querySelector('.logo-and-text');
        let title = document.querySelector('.subtitleJS');

        if (window.outerWidth < 800) {
            boxLogo.classList.add('menu-aberto');
            boxLogo.classList.remove('menu-fechado');
        } else {
            title.classList.add('titulo-hidden');
            boxLogo.classList.add('menu-aberto-desktop');
        }
    }

    return (
        <Container>
            <header>
                <Banner image={home} />

                <div className="box-center">
                    <div className="container-menu">
                        {open ? <IoCloseOutline className="icon-menu" onClick={() => menuFechando()} /> : <IoReorderTwoOutline className="icon-menu" onClick={() => menuAbrindo()} />}
                        <Menu open={open} />
                    </div>
                    <div className='logo-and-text'>
                        <img className="logo-ailleclinic" src={logo} alt="Logo Aille Clinic" />
                        <span className='text-technical-manager'>Responsável Técnico <br /> Dr. Romão A. de S. Mansano; CROSP: 79434</span>
                    </div>
                    <h2 className="subtitle subtitleJS">role a página e conheça</h2>
                </div>
            </header>

            <main>
                {/* <div id="sobre">
                    {dataText.map((text) =>
                        <CardText
                            key={text.title}
                            image={text.image}
                            title={text.title}
                            subtitle={text.subtitle}
                            type={text.type}
                            menssage={text.menssage}
                        />
                    )}
                </div> */}
                
                <CardInfo
                    key={oQueFazemos.id}
                    id={oQueFazemos.idLink}
                    type={oQueFazemos.type}
                    image={oQueFazemos.image}
                    title={oQueFazemos.title}
                    text={oQueFazemos.text}
                    ulList={oQueFazemos.ulList}
                    description={oQueFazemos.description}
                    buttonLabel={oQueFazemos.buttonLabel}
                    buttonHref={oQueFazemos.buttonHref}
                    icons={oQueFazemos.icons}
                />
                
                <div className='div-info-men space-section' id="corpo-clinico">
                    {dataProfi.map((prof) =>
                        <CardProf
                            key={prof.id}
                            name={prof.name}
                            image={prof.image}
                            profession={prof.profession}
                            description={prof.description}
                            linkedin={prof.linkedin}
                            instagram={prof.instagram}
                            crm={prof.crm}
                            rqe={prof.rqe}
                        />
                    )}
                </div>
                
                {dataInfo.map((info) => {
                    if(info.text2){
                        return <CardInfoShowMore
                            key={info.id}
                            id={info.idLink}
                            type={info.type}
                            image={info.image}
                            title={info.title}
                            text1={info.text}
                            text2={info.text2}
                            ulList={info.ulList}
                            description={info.description}
                            buttonLabel={info.buttonLabel}
                            buttonHref={info.buttonHref}
                            icons={info.icons}
                        />
                    }

                    return <CardInfo
                        key={info.id}
                        id={info.idLink}
                        type={info.type}
                        image={info.image}
                        title={info.title}
                        text={info.text}
                        ulList={info.ulList}
                        description={info.description}
                        buttonLabel={info.buttonLabel}
                        buttonHref={info.buttonHref}
                        icons={info.icons}
                    />
                })}

                {/* {dataStore.map((info) =>
                    <CardStore
                        key={info.id}
                        id={info.idLink}
                        type={info.type}
                        image={info.image}
                        title={info.title}
                        ulList={info.ulList}
                        description={info.description}
                        buttonLabel={info.buttonLabel}
                        buttonHref={info.buttonHref}
                        icons={info.icons}
                    />
                )} */}
                <Footer />
            </main>
            <Rights />
        </Container>
    );
}

export default Home;
