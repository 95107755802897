import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex: columns;
    margin: 50px 0;
    overflow: hidden;
    max-width: 1400px;
    max-height: 600px;

    .image-profi {
        width: 50%;
        object-fit: cover;
        object-position: center 30%;
    }

    .container-infos {
        width: 100%;
        max-width: 95ch;
        margin: 30px 30px;
        justify-content: space-between;
    }

    .title-name {
        margin: 10px 0;
        font-style: var(--unnamed-font-style-normal);
        font-weight: var(--unnamed-font-weight-600);
        font-size: var(--unnamed-font-size-18);
        line-height: var(--unnamed-line-spacing-18);
        letter-spacing: var(--unnamed-character-spacing-1);
        color: var(--unnamed-color-d5b68f);
        text-transform: uppercase;
    }
    
    .subtitle-name {
        font-weight: var(--unnamed-font-weight-600);
        font-size: var(--unnamed-font-size-14);
        line-height: var(--unnamed-line-spacing-14);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-827566);
        margin: 0;
    }
    
    .subtitle-name:nth-child(3) {
        color: var(--unnamed-color-827566);
        margin: 10px 0 0 0;
    }

    .container-icons {
        display: flex;
        align-items: center;
        
        .insta-icon,
        .linkedin-icon {
            color: var(--unnamed-color-d5b68f);
            font-size: var(--unnamed-font-size-24);
        }

        .insta-icon {
            margin-left: 15px;
        }
    }

    .description {
        margin: 0;
        max-width: 600px;
        font-weight: var(--unnamed-font-style-normal);
        font-size: var(--unnamed-font-size-14);
        line-height: var(--unnamed-line-spacing-24);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-4a3f32);
    }

    @media (max-width: 800px) {
        width: 100%;
        max-height: 100%;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 0;

        .image-profi {
            width: 100%;
            max-height: 600px;
            object-fit: cover;
        }

        .container-infos {
            padding: 0px;
            max-width: 100%;
            margin: 30px 0px;
        }
    }
`;