import React from 'react';
// import { Link } from 'react-router-dom';
import { Container } from './styled';

function NotFound() {
  
  return (
    <Container>
      <h1>404 - Página não encontrada</h1>
    </Container>
  )
}

export default NotFound;
