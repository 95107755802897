import styled from "styled-components";

export const ContainerFooter = styled.footer`
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;

   .container-direitos {
      width: 100%;
      padding: 50px 30px;
      background: #F7F4F0 0% 0% no-repeat padding-box;

      p {
         text-align: center;
         font-weight: var(--unnamed-font-style-normal);
         font-size: var(--unnamed-font-size-14);
         line-height: var(--unnamed-line-spacing-24);
         letter-spacing: var(--unnamed-character-spacing-0);
         color: var(--unnamed-color-4a3f32);
         margin: 0;

         /* strong {
            a {
               color: ;
            }
         } */
      }

      .signature {
         text-decoration: none;
         color: #bb2025;
      }
   }
 `;