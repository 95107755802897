import React, { useRef } from 'react';
import { Slider } from './styled';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import arrow from '../../assets/images/arrow.svg';

const ImageSlider = ({ images, id, className }) => {
    const splideRef = useRef();

    const settings = {
        lazyLoad: 'nearby',
        type     : 'slide',
        perPage  : 1,
        pagination: false,
        arrows: false,
        drag: true,
        rewind: true,
    };

    const handlePrev = () => {
        if (splideRef.current) {
            splideRef.current.splide.go('<');
        }
    };

    const handleNext = () => {
        if (splideRef.current) {
            splideRef.current.splide.go('>');
        }
    };

    return (
        <Slider className={`slider-${id} ${className}`}>
            <Splide options={settings} ref={splideRef}>
                {images.map((image, index) => (
                    <SplideSlide key={index}>
                        <img src={image} alt="" />
                    </SplideSlide>
                ))}
            </Splide>

            <button className="arrow arrow-left" onClick={handlePrev}>
                <img src={arrow} alt="Anterior" />
            </button>
            <button className="arrow arrow-right" onClick={handleNext}>
                <img src={arrow} alt="Próximo" />
            </button>
        </Slider>
    );
}

export default ImageSlider;
