import styled from 'styled-components';



export const ContainerMenu = styled.div`
    top: 30px;
    left: calc(50% - 125px);
    width: 250px;
    height: 327px;
    position: absolute;
    transition: all ease-in-out 0.8s;
    opacity:${props => props.open ? 1 : 0};
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background-color:#fff;
    @media(min-width:800px){
        top:204px;
        right:0px;
        left:none;
        transform: ${props => props.open ? 'translateX(0)' : 'translateX(100vh)'};
        transition: all ease-in-out 0.3s;
    }

    

    .menu-list-wrapper{
        margin: 30px 0 0 0;
        width:fit-content;
        width:-moz-fit-content;
        background-color:#fff;
        padding: 55px 68px;
        display:flex;
        align-items:center;
        justify-content:center;
        position:absolute;
        
    }

    .menu-list{
        list-style:none;
    }

    .list-item{
        padding: 0px 0px 35px 0px;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .list-item a{
        text-decoration: none;
        color: #827566;
        font-size:14px;
        font-weight: 600;
        text-align:center;
    }
`;