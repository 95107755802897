import React from 'react';
import { ContainerFooter } from './styled';

function Footer() {
  return (
    <ContainerFooter>
      <div className="container-direitos">
        <p>® <span>{(new Date().getFullYear())}</span> Rede Odontologia - Todos os direitos reservados. Proibido reprodução total ou parcial do conteúdo. Site por <strong><a href="https://doo.is?utm_source=redeodonto&utm_medium=post&utm_campaign=campaign-footer&utm_id=site_footer" className='signature' target='_blank' rel="noreferrer">doo.is</a></strong></p>
      </div>
    </ContainerFooter>
  );
}

export default Footer;
